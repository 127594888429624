.score{
  background-color: var(--ion-color-primary);
  border-radius: 100%;
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.score-container{
  display: flex;
  justify-content: center;
}

.star{
  font-size: 2rem;
  color: rgb(255, 196, 0);
}