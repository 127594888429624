ion-modal#feedback-modal {
  --min-width: 350px;
  --height: fit-content;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  align-items: end;
}

ion-modal#feedback-modal h2, ion-modal#feedback-modal h3, ion-modal#feedback-modal h4 {
  color: black;
  padding: 0px;
  margin: 0px 0px 0px 5px;
}

ion-modal#feedback-modal ion-icon {
  margin-right: 6px;

  width: 30px;
  height: 30px;

  padding: 4px 0;
}

.feeback-header {
  display: flex; 
  justify-content: space-between;
  align-items: center;
  padding: 1em 1em 0px 1em;
}

ion-modal#feedback-modal .wrapper {
  margin-bottom: 10px;
}