.m-0{
  margin: 0px;
}

.text-dimgray{
  color: dimgray;
}

ion-radio {
  --border-radius: 4px;
  --inner-border-radius: 4px;

  --color: #ddd;
  --color-checked: var(--ion-color-primary);
}

ion-radio.ios {
  width: 20px;
  height: 20px;

  border: 2px solid #ddd;
  border-radius: 4px;
}

.radio-checked.ios {
  border-color: var(--ion-color-primary);
}