.linkStyle {
  position: absolute;
  width: 100%; 
  height: 100%; 
  top: 0px; 
  left: 0px; 
  background-color: rgb(0 98 255 / 70%); 
  color: white; 
  padding: 0px 1.5em; 
  border-radius: 5px;
  text-decoration: none;
}

.titleStyle{ 
  padding: 0px 0.7em;
  text-align: center;
}

.linkStyle h1 {
  text-transform: capitalize;
}

.cardCategory {
  position: relative;
  background-color: rgb(0 0 0 / 50%);
  padding: 1em;
  height: 140px;
  margin: 10px 1em;
  width: 100%;
}

.cardCategoryHeader {
  display: flex;
  justify-content: space-between; 
  align-items: center;
}

.cardCategoryTitle {
  font-weight: bold;
  font-size: 1.3em;
  padding: 1px 0;
}

.iconCategory {
  position: absolute;
  height: 80%;
  width: 50%;
  color: white;
  right: 0;
}

.iconCategoryFooter{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconCategoryContent{
  margin-top: 0px;
}

@media only screen and (max-width: 375px)  {
  .iconCategoryContent{
    margin-bottom: 7px;
  }
}

@media only screen and (min-width: 750px)  {
  .cardCategory{
    width: 45%;
  }
}

@media only screen and (min-width: 1300px)  {
  .cardCategory{
    width: 30%;
  }
}